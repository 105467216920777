import TournamentActionType from './tournament.types';

const INITIAL_STATE = {
  activeTournaments: [],
  upcomingTournaments: [],
  Tournaments: [],
  topUserList: [],
  topGroupList: [],
  topPredictors: [],
  userGroupList: [],
  userGroupMembersList: [],
  matchSchedules: [],
  tournamentDetails: [],
  favouriteTeam: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TournamentActionType.SET_TOURNAMENTS:
      const activeTournaments = action.payload.filter(
        tournament => tournament.state === 'active',
      );
      const upcomingTournaments = action.payload.filter(
        tournament => tournament.state === 'upcoming',
      );

      return {
        ...state,
        activeTournaments: activeTournaments,
        upcomingTournaments: upcomingTournaments,
      };
    case TournamentActionType.SET_ACTIVE_TOURNAMENTS:
      return {
        ...state,
        activeTournaments: action.payload,
      };
    case TournamentActionType.SET_ONGOING_TOURNAMENTS:
      return {
        ...state,
        upcomingTournaments: action.payload,
      };
    case TournamentActionType.SET_TOP_USER:
      return {
        ...state,
        topUserList: action.payload,
      };
    case TournamentActionType.SET_TOP_GROUPS:
      return {
        ...state,
        topGroupList: action.payload,
      };
    case TournamentActionType.SET_TOP_PREDICTORS:
      return {
        ...state,
        topPredictors: action.payload,
      };
    case TournamentActionType.SET_USER_GROUPS:
      return {
        ...state,
        userGroupList: action.payload,
      };
    case TournamentActionType.SET_USER_GROUPS_MEMBERS:
      return {
        ...state,
        userGroupMembersList: action.payload,
      };
    case TournamentActionType.SET_MATCH_SCHEDULE:
      return {
        ...state,
        matchSchedules: action.payload,
      };
    case TournamentActionType.SET_TOURNAMENT_DETAILS:
      return {
        ...state,
        tournamentDetails: action.payload,
      };
    case TournamentActionType.SET_FAVOURITE_TEAM:
      return {
        ...state,
        favouriteTeam: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
