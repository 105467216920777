import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IPL from '../../assets/banners/ipl2024.jpg';
import T20 from '../../assets/banners/t20_2024.jpg';
import F1 from '../../assets/banners/f1_logo.jpg';
import UEFAEuro from '../../assets/banners/UEFA-Euro-2024-Logo.png';
import { formatDate } from '../../utils/dates';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  addTournamentsToUser,
  addTournamentsToGroup,
} from '../../redux/tournaments/tournament.actions';
import Container from '@mui/material/Container';
import { toast } from 'react-toastify';

const TournamentCard = ({ tournament, type, group_id = '' }) => {
  const assets = () => [
    { name: 'IPL 2024', image: IPL },
    { name: "ICC Men's T20 World Cup 2024", image: T20 },
    { name: 'F1 Racing', image: F1 },
    { name: 'UEFA EURO 2024', image: UEFAEuro },
  ];

  const getTournamentImage = t_Name => {
    const tournament = assets().find(asset => asset.name === t_Name);
    return tournament ? tournament.image : IPL;
  };
  const tournamentImage = getTournamentImage(tournament.name);

  const group = useSelector(state => state.groups.group);
  const user = localStorage.getItem('user_id');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const noMatchesPlayed = 25;
  // const noMatches = 35;

  const handleAddTournament = user_id => {
    localStorage.setItem('tournament_id', tournament.id);
    if (type === 'user') {
      dispatch(addTournamentsToUser(tournament.id));
      navigate(`/favouriteteam/${tournament.id}`);
      setSuccess(true);
    } else {
      if (user !== group.admin) {
        toast.warning('The logged user is not a admin of this group', {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
      dispatch(addTournamentsToGroup(tournament.id, group_id, user_id));
    }
    //
    // setSuccess(true);
  };

  useEffect(() => {
    if (success === true) {
      navigate(`/favouriteteam/${tournament.id}`);
    }
  }, [success, tournament, navigate]);

  const handleCardClick = () => {
    if (type === 'group-tournament' || type === 'user') {
      localStorage.setItem('tournament_name', tournament.name);
      localStorage.setItem('tournament_id', tournament.id);
      localStorage.setItem('is_participated', tournament.is_participated);
      localStorage.setItem('user_points', tournament.user_points);
      navigate(`/tournaments/${tournament.id}`);
    }
  };

  return (
    <Container sx={{ mt: 3 }}>
      {type === 'user' ? (
        <div>
          <Card
            sx={{
              minWidth: 345,
              maxWidth: '100%',
              boxShadow: '0 0 10px #ccc',
              cursor: 'pointer',
            }}
          >
            {tournamentImage && (
              <CardMedia
                sx={{ height: 200 }}
                image={getTournamentImage(tournament.name) || IPL}
                title={tournament.name}
                onClick={handleCardClick}
              />
            )}
            <CardContent sx={{ pb: 0 }} onClick={handleCardClick}>
              <Typography variant="h5" textAlign={'left'}>
                {tournament.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={'left'}
              >
                Started On: {formatDate(tournament.start_date)} | Ends On:{' '}
                {formatDate(tournament.end_date)}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                pt: 0,
                px: 2,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={'left'}
              >
                {/* {noMatchesPlayed}/{noMatches} Played */}
              </Typography>
              {type === 'group-tournament' ? null : type === 'group' ? (
                tournament.is_group_participated ? (
                  <Button size="small" disabled>
                    joined
                  </Button>
                ) : (
                  <Button size="small" onClick={handleAddTournament}>
                    join
                  </Button>
                )
              ) : tournament?.user_details?.is_participated ? (
                <Button size="small" disabled>
                  joined
                </Button>
              ) : (
                <Button size="small" onClick={handleAddTournament}>
                  join
                </Button>
              )}
            </CardActions>
          </Card>
        </div>
      ) : (
        <div onClick={handleCardClick}>
          <Card
            sx={{
              minWidth: 345,
              maxWidth: '100%',
              boxShadow: '0 0 10px #ccc',
              cursor: 'pointer',
            }}
          >
            {tournamentImage && (
              <CardMedia
                sx={{ height: 200 }}
                image={getTournamentImage(tournament.name) || IPL}
                title={tournament.name}
              />
            )}
            <CardContent sx={{ pb: 0 }}>
              <Typography variant="h5" textAlign={'left'}>
                {tournament.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={'left'}
              >
                Started On: {formatDate(tournament.start_date)} | Ends On:{' '}
                {formatDate(tournament.end_date)}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                pt: 0,
                px: 2,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={'left'}
              >
                {/* {noMatchesPlayed}/{noMatches} Played */}
              </Typography>
              {type === 'group-tournament' ? null : type === 'group' ? (
                tournament.is_group_participated ? (
                  <Button size="small" disabled>
                    joined
                  </Button>
                ) : (
                  <Button size="small" onClick={handleAddTournament}>
                    join
                  </Button>
                )
              ) : tournament.is_participated ? (
                <Button size="small" disabled>
                  joined
                </Button>
              ) : (
                <Button size="small" onClick={handleAddTournament}>
                  join
                </Button>
              )}
            </CardActions>
          </Card>
        </div>
      )}
    </Container>
  );
};

export default TournamentCard;
