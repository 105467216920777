import React, { useState, useEffect } from 'react';
import { Container, Typography, InputLabel } from '@mui/material';
import { Grid, Button } from '@mui/material';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from '../../utils/theme';
import { signup } from '../../redux/auth/auth.actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAccountCreated } from '../../redux/actions/action.actions';

const ErrorLabel = styled(Typography)(() => ({
  color: 'red',
  fontSize: '0.875rem',
  fontWeight: 500,
  marginBottom: '15px',
  textAlign: 'left',
}));

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const SignUp = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accountCreated = useSelector(state => state.actions.accountCreated);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    country_code: '',
    password: '',
    confirm_password: '',
    referral_code: '',
  });

  useEffect(() => {
    // Check if there's a referral code in the URL
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('referral_code');
    if (referralCode) {
      setValues(prevValues => ({
        ...prevValues,
        referral_code: referralCode,
      }));
    }
  }, [location.search]);


  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    otp: '',
  });

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleValidation = () => {
    const newErrors = {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      password: '',
      confirm_password: '',
    };

    if (!values.email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(values.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!values.password.length) {
      newErrors.password = 'Password is required';
    }

    if (!values.first_name) {
      newErrors.first_name = 'First name is required';
    }

    if (!values.last_name) {
      newErrors.last_name = 'Last name is required';
    }

    if (values.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (values.password !== values.confirm_password) {
      newErrors.confirm_password = 'Passwords are not matching';
    }

    setErrors(newErrors);
    return (
      !newErrors.password &&
      !newErrors.confirm_password &&
      !newErrors.first_name &&
      !newErrors.last_name
    );
  };

  const handleSignup = async e => {
    if (handleValidation()) {
      e.preventDefault();
      try {
        dispatch(
          signup({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            referral_code: values.referral_code,
          }),
        );
      } catch (error) {
        console.error('Error during signup:', error);
      }
    }
  };

  const handleToggle = () => {
    navigate('/');
  };

  const handleChange = prop => e => {
    setValues({ ...values, [prop]: e.target.value });
  };

  useEffect(() => {
    if (accountCreated && location.pathname === '/signup') {
      navigate('/');
      setAccountCreated(null);
    }
    // eslint-disable-next-line
  }, [accountCreated]);

  return (
    <>
      <Container
        sx={{
          height: '100vh',
          padding: '0!important',
          background: 'palette.background.default',
        }}
        maxWidth={'100vw'}
      >
        <Grid container style={{ width: '100%' }}>
          <Grid
            item
            xs={0}
            md={6}
            style={{
              position: 'sticky',
              top: 0,
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <div
              className="landing-image"
              style={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            ></div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card variant="outlined" sx={{ width: '100%', maxWidth: '500px' }}>
              <CardHeader sx={{ textAlign: 'center' }} title="Sign up" />
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="first-name" sx={{ textAlign: 'left' }}>
                      First Name *
                    </InputLabel>
                    <InputField
                      id="first-name"
                      fullWidth
                      margin="normal"
                      onChange={handleChange('first_name')}
                      value={values.first_name}
                    />
                    <ErrorLabel>{errors.first_name}</ErrorLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <InputLabel htmlFor="last-name" sx={{ textAlign: 'left' }}>
                      Last name *
                    </InputLabel>
                    <InputField
                      id="last-name"
                      type="text"
                      fullWidth
                      margin="normal"
                      onChange={handleChange('last_name')}
                      value={values.last_name}
                    />
                    <ErrorLabel>{errors.last_name}</ErrorLabel>
                  </Grid>
                </Grid>
                <InputLabel htmlFor="email" sx={{ textAlign: 'left' }}>
                  Email *
                </InputLabel>
                <InputField
                  id="email"
                  type="email"
                  fullWidth
                  margin="normal"
                  onChange={handleChange('email')}
                  value={values.email}
                />
                <ErrorLabel>{errors.email}</ErrorLabel>
                <InputLabel htmlFor="new-password" sx={{ textAlign: 'left' }}>
                  Create password *
                </InputLabel>
                <InputField
                  id="new-password"
                  type="password"
                  fullWidth
                  margin="normal"
                  onChange={handleChange('password')}
                  value={values.password}
                />
                <InputLabel
                  htmlFor="confirm-password"
                  sx={{ textAlign: 'left' }}
                >
                  Re-enter your password *
                </InputLabel>
                <InputField
                  id="confirm-password"
                  type="password"
                  fullWidth
                  margin="normal"
                  onChange={handleChange('confirm_password')}
                  value={values.confirm_password}
                />
                <InputLabel htmlFor="referral-code" sx={{ textAlign: 'left' }}>
                Referral Code
                </InputLabel>
                <InputField
                  id="referral-code"
                  type="text"
                  fullWidth
                  margin="normal"
                  onChange={handleChange('referral_code')}
                  value={values.referral_code}
                />
                <ErrorLabel>{errors.confirm_password}</ErrorLabel>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end'
                  }}
                >
                  <Typography
                    variant="body2"
                  >
                    Already have an account?{' '}
                    <span
                      style={{ cursor: 'pointer', color: '#568A60' }}
                      onClick={handleToggle}
                    >
                      Sign In
                    </span>
                  </Typography>
                  <Button variant="contained" onClick={handleSignup}>
                    Sign Up
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SignUp;
