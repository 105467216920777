import {
  Button,
  Box,
  Card,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addFavouriteTeamToUser } from '../../redux/tournaments/tournament.actions';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const TeamNameCard = ({ team, tournament, user, search }) => {
  const dispatch = useDispatch();
  const [selectedTeam, setSelectedTeam] = useState('');
  const t_Details = useSelector(state => state.tournaments.tournamentDetails);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleClick = teamname => {
    dispatch(addFavouriteTeamToUser(tournament, teamname));
    setSuccess(true);
  };
  useEffect(() => {
    setSelectedTeam(t_Details?.user_details?.favourite_team);
  }, [t_Details]);

  useEffect(() => {
    if (success === true) {
      navigate(`/tournaments/${tournament}`);
    }
  }, [success, navigate, tournament]);
  const isSearchedTeam = team.teams.name.toLowerCase() === search.toLowerCase();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLaptop = useMediaQuery('(min-width:600px)');

  const style = {
    media: {
      height: '45px',
      width: '90px',
      marginBottom: '10px',
      marginTop: '10px',
    },
    media1: {
      height: '45px',
      width: '90px',
      marginBottom: '10px',
      marginTop: '10px',
      border: '5px solid blue',
    },
    card: {
      width: isMobile ? 200 : 200,
      boxShadow: '0 0 10px #ccc',
      height: '140px',
      margin: '10px 10px',
    },
    card1: {
      width: isMobile ? 200 : 200,
      boxShadow: '0 0 10px #ccc',
      height: '140px',
      margin: '10px 10px',
      backgroundColor: 'grey',
      border: '2px solid blue',
    },
    cardsearch: {
      width: isMobile ? 200 : 200,
      boxShadow: '0 0 10px #ccc',
      height: '140px',
      margin: '10px 10px',
      backgroundColor: 'lightblue',
      border: '2px solid blue',
    },
    h6: {
      fontSize: '0.875rem',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: '500',
      color: '#f6f6f6',
    },
    h4: {
      fontSize: '1.2rem',
      fontFamily: 'fantasy',
      fontWeight: '550',
      marginBottom: '10px',
      color: 'black',
    },
    container: {
      gap: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px',
      justifyContent: 'center',
      padding: isLaptop ? 0 : 0,
      width: 'auto',
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '140px',
      padding: '0px 10px 0 10px',
      backgroundColor: 'rgb(57 57 57 / 5%)',
    },
    grid2: {
      backgroundColor: '#000000c9',
      height: '80px',
      width: '100%',
      padding: '12px 15px 0 15px',
      color: 'black',
      display: 'block',
      textAlign: 'left',
    },
  };

  return (
    <>
      <Box sx={style.container}>
        <Card
          style={
            selectedTeam === team.teams.name
              ? style.card1
              : isSearchedTeam
              ? style.cardsearch
              : style.card
          }
        >
          <Grid>
            <CardMedia
              component="img"
              image={team?.teams.logo}
              alt={team?.teams.name}
              style={style.media}
            />

            <Typography style={style.h4} component="h5" variant="h5">
              {team?.teams.name}
            </Typography>

            <Button
              size="sm"
              color="primary"
              sx={{
                height: '25px',
                marginTop: '5px',
                backgroundColor: '#d48448fc',
                borderRadius: '7px',
                fontWeight: '600',
                fontSize: '12px',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#d48448fc',
                },
              }}
              onClick={() => handleClick(team.teams.name)}
            >
              select
            </Button>
          </Grid>
        </Card>
      </Box>
    </>
  );
};
export default TeamNameCard;
