import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Avatar,
  Chip,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { formatDate, formatTime } from '../../utils/dates';
import { Slider } from '@mui/material';
import MainQuestion from './MainQuestion';
import bat from '../../assets/skillsIcon/bat.png';
import ball from '../../assets/skillsIcon/ball.png';
import allRound from '../../assets/skillsIcon/all-roun.jpg';
import wk from '../../assets/skillsIcon/wk.jpg';
import defaultIcon from '../../assets/skillsIcon/default.jpg';
import FT from '../../assets/skillsIcon/ftp.png';
import GK from '../../assets/skillsIcon/GK.jpg';

const Match = ({
  questions,
  options,
  players,
  answers,
  points,
  handleCardClick,
  selectedCard,
  handlePointsChange,
  handleOptionChange,
  pointsScored,
  matchDetails,
  disabled = false,
}) => {
  const [mainQuestion, setMainQuestion] = useState(null);

  useEffect(() => {
    setMainQuestion(questions.find(q => q.type === 'Team'));
  }, [questions]);

  const icons = [
    { name: 'Batsmen ', icon: bat },
    { name: 'Batter', icon: bat },
    { name: 'Bowler', icon: ball },
    { name: 'Bowler ', icon: ball },
    { name: 'Wicket-keeper', icon: wk },
    { name: 'All-rounder', icon: allRound },
    { name: 'Defender', icon: FT },
    { name: 'Midfielder', icon: FT },
    { name: 'Forward', icon: FT },
    { name: 'Goalkeeper', icon: GK },
    { name: '', icon: defaultIcon },
  ];
  
  const getPlayerIcon = (skillName) => {
    const skill = icons.find((icon) => icon.name === skillName);
    return skill ? skill.icon : defaultIcon;
  };
  
  const renderPlayerMenuItems = (teamPlayers) => {
    if (!Array.isArray(teamPlayers) || teamPlayers.length === 0) {
      return null; // To Handle the case when teamPlayers is undefined or empty
    }
  
    // Sorted teamPlayers alphabetically by player name
    const sortedPlayers = teamPlayers.sort((a, b) => a.name.localeCompare(b.name));
  
    return sortedPlayers
      .filter(
        (player) =>
          player.skills === 'Batter' ||
          player.skills === 'Batsmen ' ||
          player.skills === 'Wicket-keeper' ||
          player.skills === 'All-rounder' ||
          player.skills === 'Bowler' ||
          player.skills === 'Bowler ' ||
          player.skills === 'Goalkeeper' ||
          player.skills === 'Defender' ||
          player.skills === 'Midfielder' ||
          player.skills === 'Forward' 
      )
      .sort((a, b) => {
        const order = [
          'Batsmen ',
          'Batter',
          'Wicket-keeper',
          'All-rounder',
          'Bowler',
          'Bowler ',
          'Goalkeeper',
          'Defender',
          'Midfielder',
          'Forward',
        ];
        return order.indexOf(a.skills) - order.indexOf(b.skills);
      })
      .map((player, index) => (
        <MenuItem key={index} value={player.id}>
          <Chip
            avatar={
              <Avatar
                alt={player.skills}
                src={getPlayerIcon(player.skills)}
              />
            }
            sx={{ backgroundColor: 'inherit' }}
          />
          {player.name}
        </MenuItem>
      ));
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '700px' }}>
      <Typography
        variant="h6"
        fontWeight={'bolder'}
        color={'#312e2e'}
        marginTop={1}
      >
        {mainQuestion?.question}
      </Typography>
      <Box
        container
        display={'flex'}
        sx={{
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          marginTop: 1,
        }}
      >
        <Typography fontSize={'15px'} fontWeight={400}>
          {'Venue: '}
          {players?.venue}
        </Typography>
        {players && (
          <Typography fontSize={'15px'} fontWeight={400}>
            {'Date & Time: '}
            {formatDate(players?.start_date, players?.start_time)} |&nbsp;
            {formatTime(players?.start_date, players?.start_time)}
          </Typography>
        )}
      </Box>
      <MainQuestion
        question={mainQuestion}
        handleCardClick={handleCardClick}
        options={options}
        selectedCard={selectedCard}
        disabled={disabled}
      />
      {pointsScored[mainQuestion?.id] ? (
        <Typography
          sx={{ width: '100%', mt:2, color: 'black', maxWidth: '500px', textAlign: { xs:'center', sm:'start'}}}
          fontSize={'13px'}
        >
          {
            matchDetails.result === 'Tie'
              ? `Result - Match Tied.`          // Tied Match
              : `Winner: ${pointsScored[mainQuestion?.id].answer} | Points Scored: ${pointsScored[mainQuestion?.id].points}` // Winner
          }
        </Typography>
      ) : (
        <>
        <Typography
          sx={{ width: '100%', mt:2, color: 'black', maxWidth: '500px', textAlign: { xs:'center', sm:'start'}}}
          fontSize={'13px'}
        >
          {
            matchDetails.result === 'Cancelled'
              ? `Result - Match Cancelled. ❌` // Cancelled Match
              :  matchDetails.result === 'Tie'
              ? `Result - Match Tied.`          // Tied Match
              : '' // Winner
          }
        </Typography></>
      )}

      <Grid display={'block'} gap={2}>
        <Grid mt={6}>
          <Slider
            aria-label="Bet points"
            defaultValue={500}
            disabled={disabled}
            onChange={handlePointsChange}
            value={points}
            valueLabelDisplay="on"
            shiftStep={500}
            step={50}
            marks
            min={100}
            max={1000}
            sx={{
              color: '#348954',
              width: '90%',
              maxWidth: '500px',
            }}
          />
        </Grid>
        {!pointsScored?.total_points && (
          <Typography fontSize={'small'} mt={0}>
            Slide to select your points
          </Typography>
        )}
      </Grid>
      {questions
        .filter(q => q.type !== 'Team')
        .map(question => (
          <Grid
            item
            display={'flex'}
            sx={{
              justifyContent: 'center',
              width: '500px',
              maxWidth: '100%',
              marginY: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <FormControl
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              <InputLabel id={`select-label-${question.id}`}>
                {question.question}
              </InputLabel>
              <Select
                disabled={disabled}
                labelId="simple-select-label"
                id="simple-select"
                value={`${
                  answers[question.id] ? answers[question.id]?.answer : ''
                }`}
                label={question.question}
                placeholder={question.question}
                fullWidth
                onChange={event => handleOptionChange(event, question)}
              >
                {question.question_category === 'Match_Players' &&(
                  <>
                <MenuItem
                  sx={{
                    pointerEvents: 'none',
                    background: '#eee',
                    justifyContent: 'center',
                  }}
                  value=""
                >
                  {matchDetails?.match_details?.team1}
                </MenuItem>
                {renderPlayerMenuItems(players?.team1_players)}

                <MenuItem
                  sx={{
                    pointerEvents: 'none',
                    background: '#eee',
                    justifyContent: 'center',
                  }}
                  value=""
                >
                  {matchDetails?.match_details?.team2}
                </MenuItem>
                {renderPlayerMenuItems(players?.team2_players)}
                </>
                )}
                 {question.question_category === 'TeamA_Players' &&(
                  <>
                    <MenuItem
                  sx={{
                    pointerEvents: 'none',
                    background: '#eee',
                    justifyContent: 'center',
                  }}
                  value=""
                >
                  {matchDetails?.match_details?.team1}
                </MenuItem>
                {renderPlayerMenuItems(players?.team1_players)}
                  </>
                 )}
                  {question.question_category === 'TeamB_Players' &&(
                  <>
                    <MenuItem
                  sx={{
                    pointerEvents: 'none',
                    background: '#eee',
                    justifyContent: 'center',
                  }}
                  value=""
                >
                  {matchDetails?.match_details?.team2}
                </MenuItem>
                {renderPlayerMenuItems(players?.team2_players)}
                  </>
                 )}
              </Select>
            </FormControl>
            {pointsScored[question?.id] ? (
              <Box sx={{ color: 'black', fontSize: 'small' }}>
                Correct Answer: {pointsScored[question?.id].answer} | Points
                Scored: {pointsScored[question?.id].points}
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        ))}
    </Box>
  );
};

export default Match;
