import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Divider, Card } from '@mui/material';
import Container from '../../sharedComponents/Container';
import { getReferralDetails } from '../../redux/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Padding } from '@mui/icons-material';

const ReferralLinkPage = () => {
  const dispatch = useDispatch();
  const [copiedMessage, setCopiedMessage] = useState('');
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.auth.referralDetails);
  const [code, setCode] = useState('');
  const [link, setLink] = useState('');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedMessage(`${text} copied to clipboard!`);
    setTimeout(() => {
      setCopiedMessage('');
    }, 1500);
  };

  useEffect(() => {
    dispatch(getReferralDetails());
  }, [dispatch ] );

  useEffect(() => {
  if (user) {
    setCode(user?.referral_code);
    setLink(user?.referral_link);
    };
  }, [user] );

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted email:', email);
    setEmail('');
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const style = {
    title: {
      fontSize: { xs:'30px', sm:'40px'},
      fontWeight: 800,
      textAlign: 'center',
    },
    text: {
      fontSize: '16px',
      textAlign: 'center',
      width: { xs:'90%', sm:'60%', md:'61%'},
      "&::value": {
              color: "green",
            },
          },
    textTitle: {
      fontSize: '16px',
      textAlign: 'center',
    },
    copiedMessage: {
      fontSize: '14px',
      color: 'green',
      textAlign: 'center',
      mt:2,
    },
    button: {
      marginLeft: '10px',
    },
  };

  return (
    <Container>
    <Box sx={{ p: 2 }}>
      <Typography variant="h2" component="h3" sx={style.title}>
        Earn 100 coins on every referral!
      </Typography>
      <Card sx={{
        mt:3,
        maxWidth: "800px", minWidth: "300px",
      }}>
        <Box sx={{ mt: 5 }}>
        <Typography variant="body1" sx={style.textTitle} fontWeight={'700'} mb={1} mt={3}>
          Copy this code to refer your friends!
        </Typography>
        <TextField
          id="referral-code"
          label="Referral Code"
          variant="outlined"
          size="small"
          value={code}
          // width={'fit-content'}
          sx={style.text}
          
          InputProps={{
            endAdornment: (
              <Button
                variant="outlined"
                size="small"
                sx={style.button}
                onClick={() => copyToClipboard(code)}
              >
                Copy
              </Button>
            ),
          }}
        />
      </Box>
      <Box sx={{ mt: 2, }}>
        <Typography variant="body1" sx={style.textTitle} fontWeight={'700'} mb={1} mt={3}>
          Copy & Share this link to refer your friends!
        </Typography>
        <TextField
          id="referral-link"
          label="Referral Link"
          variant="outlined"
          size="small"
          value={link}
          sx={style.text}
          InputProps={{
            endAdornment: (
              <Button
                variant="outlined"
                size="small"
                sx={style.button}
                onClick={() => copyToClipboard(link)}
              >
                Copy
              </Button>
            ),
          }}
        />
        
      </Box>
      {copiedMessage && (
          <Typography variant="body2" sx={style.copiedMessage}>
            {copiedMessage}
          </Typography>
        )}

      <Divider sx={{ my: 2, margin:'50px 10px' }} />

      <Typography variant="body1" sx={style.text} fontWeight={'700'} mb={1} mt={3}>
        Enter your email to share referral details with friends!
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Email Address"
          variant="outlined"
          size="medium"
          value={email}
          onChange={handleChange}
          sx={{ mt: 1, mb: 2, width: '90%' }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          sx={{ mb: 2 }}
        >
          Submit
        </Button>
      </form>
      </Card>

    </Box>
    </Container>
  );
};

export default ReferralLinkPage;