import React, { useEffect } from 'react';
import { Typography, Button, TextField, InputAdornment } from '@mui/material';
import { useState } from 'react';
import Container from '../../sharedComponents/Container';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouriteTeam } from '../../redux/tournaments/tournament.actions';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TeamNameCard from '../../components/TeamCard';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SearchIcon from '@mui/icons-material/Search';
const FavouriteTeam = () => {
  const dispatch = useDispatch();
  const { tournament_id } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  // const tournaments = useSelector(state => state.auth.tournaments) || [];
  const teams = useSelector(state => state.tournaments.favouriteTeam) || [];
  const [selectedTeam, setSelectedTeam] = useState('');
  const t_Details = useSelector(state => state.tournaments.tournamentDetails);

  const user = localStorage.getItem('user_id');

  // const group = useSelector(state => state.groups.group);
  const navigate = useNavigate();

  const handleSkip = user_id => {
    navigate(`/tournaments/${tournament_id}`);
  };

  useEffect(() => {
    dispatch(getFavouriteTeam(tournament_id));
  }, [dispatch, tournament_id]);

  useEffect(() => {
    setSelectedTeam(t_Details?.user_details?.favourite_team);
  }, [t_Details]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 6,
    },
    avatar: {},
    form: {
      width: '100%',
      marginTop: 4,
    },
    submit: {
      margin: '30px, 0, 20px',
    },
  };

  return (
    <Container>
      <Box component="main">
        <div className={styles.container}>
          <Box
            sx={{
              boxSizing: 'inherit',
              width: '90%',
              marginTop: '10px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', sm: 'center', md:'space-between' },
              gap: { xs: '20px', sm:'30px'},
            }}
          >
            <Typography component="h1" variant="h5" fontWeight={'800'}>
              Select Your Favourite Team
            </Typography>

            <TextField
              variant="outlined"
              placeholder="Search Teams"
              size="small"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              sx={{ minWidth: '200px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="button"
              sx={{ width: '120px' }}
              variant="contained"
              color="primary"
              className={styles.submit}
              onClick={handleSkip}
            >
              {selectedTeam ? 'Back' : 'Skip'}{' '}
              <ArrowCircleRightIcon sx={{ marginLeft: '10px' }} />
            </Button>
          </Box>
          <Box sx={{ mt: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {teams.map(team => (
                <TeamNameCard
                  team={team}
                  tournament={tournament_id}
                  user={user}
                  search={searchQuery}
                />
              ))}
            </Box>
          </Box>
        </div>
      </Box>
    </Container>
  );
};

export default FavouriteTeam;
