import { takeEvery, put } from 'redux-saga/effects';
import { GET, POST, PATCH } from '../../axios';
import TournamentActionTypes from './tournament.types';
import * as TournamentActions from './tournament.actions';
import * as MatchActions from '../matches/match.actions';
import { setError } from '../actions/action.actions';
import urls from '../urls';
import { toast } from 'react-toastify';

function* fetchTournamentsSaga(tournament) {
  try {
    let status = tournament.status;
    let group_id = tournament.group_id;
    let url = `${urls.tournaments}?group_id=${group_id}`;
    if (status) {
      url = `${urls.tournaments}?status=${encodeURIComponent(
        status,
      )}&group_id=${group_id}`;
    }

    const data = yield GET(url);

    if (data && data.data) {
      yield put(TournamentActions.setTournaments(data.data));
    } else {
      let errorMessage =
        'An error occurred while fetching tournaments. Please try again later.';
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during tournament fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchAllTournamentsSaga() {
  try {
    const statusArray = ['active', 'upcoming'];
    for (const status of statusArray) {
      const data = yield GET(
        `${urls.tournaments}?status=${encodeURIComponent(
          status,
        )}&type=${encodeURIComponent('user')}`,
      );
      if (data && data.data) {
        const tournaments = data.data;
        if (status === 'active') {
          yield put(TournamentActions.setActiveTournaments(tournaments));
        } else if (status === 'upcoming') {
          yield put(TournamentActions.setOngoingTournaments(tournaments));
        }
      } else {
        let errorMessage = `An error occurred while fetching tournaments with status ${status}. Please try again later.`;
        if (data.detail) {
          errorMessage = data.detail;
        }
        yield put(setError(errorMessage));
      }
    }
  } catch (error) {
    console.error('Error during tournament fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* addTournamentToUserSaga(tournament) {
  try {
    const user_id = sessionStorage.getItem('user_id');
    const data = yield POST(`/users/${user_id}/tournament/`, {
      tournament: tournament.tournament_id,
    });
    if (data && data.success === true) {
      toast.success('Successfully joined the tournament', {
        position: 'top-center',
        autoClose: 3000,
      });

      yield put(TournamentActions.fetchAllTournaments());
      yield put(MatchActions.getUserMatches());
    } else {
      let errorMessage =
        'An error occurred while adding tournaments. Please try again later.';
      if (data.message) {
        errorMessage = data.message;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error while adding tournaments:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* addTournamentToGroupSaga(inputs) {
  try {
    const tournament_id = inputs.tournament_id;
    const group_id = inputs.group_id;
    const data = yield POST(`${urls.groups}${group_id}/tournament/`, {
      tournament: tournament_id,
    });
    if (data && data.success === true) {
      toast.success('Tournament Added to Group Successfully', {
        position: 'top-center',
        autoClose: 5000,
      });
      yield put(
        TournamentActions.getUserGroupsMembers(tournament_id, group_id),
      );
      yield put(TournamentActions.getTournaments('current', group_id));
    } else {
      let errorMessage =
        'An error occurred while adding tournaments. Please try again later.';
      if (data.message) {
        errorMessage = data.message;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error while adding tournaments:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getTopUserSaga(inputs) {
  try {
    const tournament_id = inputs.tournament_id;
    const data = yield GET(
      `${urls.questions}${tournament_id}/top_users_display/`,
    );
    if (data && data.data) {
      const userList = data.data;
      yield put(TournamentActions.setTopUser(userList));
    } else {
      let errorMessage = `An error occurred while fetching top users. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during top users fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getTopGroupSaga(inputs) {
  try {
    const tournament_id = inputs.tournament_id;
    const data = yield GET(
      `${urls.questions}${tournament_id}/top_groups_display/`,
    );
    if (data && data.data) {
      const groupList = data.data;
      yield put(TournamentActions.setTopGroups(groupList));
    } else {
      let errorMessage = `An error occurred while fetching top groups. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during top groups fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getTopPredictors(inputs) {
  try {
    const tournament_id = inputs.tournament_id;
    const data = yield GET(`${urls.questions}${tournament_id}/top_predictors/`);
    if (data && data.data) {
      const groupList = data.data;
      yield put(TournamentActions.setTopPredictors(groupList));
    } else {
      let errorMessage = `An error occurred while fetching top groups. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during top groups fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getUserGroupSaga(inputs) {
  try {
    const tournament_id = inputs.tournament_id;
    const data = yield GET(`${urls.tournaments}${tournament_id}/user_groups/`);
    if (data && data.data) {
      const userGroupList = data.data;
      yield put(TournamentActions.setUserGroups(userGroupList));
    } else {
      let errorMessage = `An error occurred while fetching top groups. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during top groups fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getUserGroupMembersSaga(inputs) {
  try {
    const group_id = inputs.group_id;
    const tournament_id = inputs.tournament_id;
    const data = yield GET(
      `${urls.tournaments}${group_id}/group_member_details/?tournament=${tournament_id}`,
    );
    if (data && data.data) {
      const userGroupMembersList = data.data;
      yield put(TournamentActions.setUserGroupsMembers(userGroupMembersList));
    } else {
      let errorMessage = `An error occurred while fetching groups members. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during groups members fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getMatchSchedulesSaga(inputs) {
  try {
    const tournamentId = inputs.tournament_id;
    const userId = inputs.userId;
    let url = `${urls.tournaments}${tournamentId}/tournament_matches`;
    if (userId) url += `?user=${userId}`;
    const data = yield GET(url);
    if (data && data.data) {
      const matchSchedules = data.data;
      yield put(TournamentActions.setMatchSchedules(matchSchedules));
    } else {
      let errorMessage = `An error occurred while fetching groups members. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during groups members fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchTournamentDetailsSaga({ tournamentId, userId }) {
  try {
    let url = `${urls.tournaments}${tournamentId}`;
    if (userId) url += `?user=${userId}`;
    const data = yield GET(url);
    if (data?.data) {
      const tournamentDetails = data.data;
      yield put(TournamentActions.setTournamentDetails(tournamentDetails));
    } else {
      let errorMessage = `An error occurred while fetching groups members. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during groups members fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* getFavouriteTeamSaga(tournament_id) {
  try {
    const tournamentId = tournament_id;
    // const userId = inputs.userId;
    let url = `${urls.tournaments}${tournamentId.tournamentId}/tournament_teams`;
    // if (userId) url += `?user=${userId}`;
    const data = yield GET(url);
    if (data && data.data) {
      const favouriteTeams = data.data;
      yield put(TournamentActions.setFavouriteTeam(favouriteTeams));
    } else {
      let errorMessage = `An error occurred while fetching groups members. Please try again later.`;
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during groups members fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* addFavouriteTeamToUser(inputs) {
  try {
    const user_id = sessionStorage.getItem('user_id');

    const data = yield PATCH(`/users/${user_id}/favourite_team/`, {
      favourite_team: inputs.teamname,
      tournament_id: inputs.tournament_id,
    });
    if ((data && data.success === true) || data.success === 'True') {
      toast.success('Favourite Team Added Successfully', {
        position: 'top-center',
        autoClose: 2000,
      });

      yield put(TournamentActions.fetchAllTournaments());
      yield put(MatchActions.getUserMatches());
    } else {
      let errorMessage =
        'An error occurred while adding favourite team. Please try again later.';
      if (data.message) {
        errorMessage = data.message;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error while adding tournaments:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

export function* tournamentSagas() {
  yield takeEvery(TournamentActionTypes.GET_TOURNAMENTS, fetchTournamentsSaga);
  yield takeEvery(
    TournamentActionTypes.FETCH_ALL_TOURNAMENTS,
    fetchAllTournamentsSaga,
  );
  yield takeEvery(
    TournamentActionTypes.ADD_TOURNAMENTS_TO_USER,
    addTournamentToUserSaga,
  );
  yield takeEvery(
    TournamentActionTypes.ADD_TOURNAMENTS_TO_GROUP,
    addTournamentToGroupSaga,
  );
  yield takeEvery(TournamentActionTypes.GET_TOP_USER, getTopUserSaga);
  yield takeEvery(TournamentActionTypes.GET_TOP_GROUPS, getTopGroupSaga);
  yield takeEvery(TournamentActionTypes.GET_TOP_PREDICTORS, getTopPredictors);
  yield takeEvery(TournamentActionTypes.GET_USER_GROUPS, getUserGroupSaga);
  yield takeEvery(
    TournamentActionTypes.GET_USER_GROUPS_MEMBERS,
    getUserGroupMembersSaga,
  );
  yield takeEvery(
    TournamentActionTypes.GET_MATCH_SCHEDULE,
    getMatchSchedulesSaga,
  );
  yield takeEvery(
    TournamentActionTypes.GET_TOURNAMENT_DETAILS,
    fetchTournamentDetailsSaga,
  );
  yield takeEvery(
    TournamentActionTypes.GET_FAVOURITE_TEAM,
    getFavouriteTeamSaga,
  );
  yield takeEvery(
    TournamentActionTypes.ADD_FAVOURITETEAM_TO_USER,
    addFavouriteTeamToUser,
  );
}
