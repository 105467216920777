import AuthActionType from "./auth.types"

const INITIAL_STATE = {
    token: null,
    user: null,
    userDetails: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActionType.SET_TOKEN:
            localStorage.setItem("token", action.token)
            return {
                ...state,
                token: action.token,
            }
        case AuthActionType.LOGOUT:
            localStorage.clear();
            return {
                ...state,
                token: null,
                user: null,
            }
        case AuthActionType.SET_USER:
            return {
                ...state,
                user: action.user
            }
        case AuthActionType.SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload
                }
        case AuthActionType.SET_REFERRAL_DETAILS:
            return {
                ...state,
                referralDetails: action.payload
                }
        default:
            return state
    }
}

export default reducer;
